// extracted by mini-css-extract-plugin
export var Bold = "GraffitiGlossaire-module--Bold--5U6ev";
export var ContactLink = "GraffitiGlossaire-module--ContactLink--BBcNL";
export var Contactlink = "GraffitiGlossaire-module--Contactlink--PzsB8";
export var DateWrapper = "GraffitiGlossaire-module--DateWrapper--JIv3f";
export var DevelopmentWrapper = "GraffitiGlossaire-module--DevelopmentWrapper--XkauH";
export var LegalMentionsWrapper = "GraffitiGlossaire-module--LegalMentionsWrapper--eVY-6";
export var Link = "GraffitiGlossaire-module--Link--hwYG0";
export var NameValueWrapper = "GraffitiGlossaire-module--NameValueWrapper--FKYF5";
export var RowWrapper = "GraffitiGlossaire-module--RowWrapper--XDPtp";
export var Wrapper = "GraffitiGlossaire-module--Wrapper--iFiqU";
export var link = "GraffitiGlossaire-module--link--BMx9l";