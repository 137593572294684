import * as React from "react";
import {
  Wrapper,
  LegalMentionsWrapper,
  RowWrapper,
  DateWrapper,
} from "./GraffitiGlossaire.module.css";
import { GrayTitle } from "../../../../css/Title.module.css";

const HistoireGlossaire = () => {
  return (
    <div className={Wrapper}>
      <h1 className={GrayTitle} >
        GLOSSAIRE
      </h1>
      
      <div className={LegalMentionsWrapper}>
        <div className={RowWrapper}>
          <div>
            <h1 style={{display: "flex", flexDirection: "row", alignItems: "flex-end"}}>Expérience de rue <h3 style={{paddingLeft: "10px"}}>glossaire de rue</h3></h1>
            <p> <strong>1969-1972 premiers graffeurs New-Yorkais</strong>: Taki 183, PhaseII, StayHigh 149, Snake1, Coco 144 , Futura 2000, Blade, Tracy 168, Noc 167…</p>
            <br/>
            <p> <strong>1973</strong>: Seen, Quik ...</p>
            <br/>
            <p><strong>1975 </strong>: Dondi White, Iz the Wiz, KaseII, Fab5Freddy, Freedom, Lee Quinones…</p>
            <br/>
            <p><strong>1976 </strong>: Crash, Zephyr, Bill Blast, Lady Pink, Revolt, Daze, Duro, Toxic, Aone, Rammellzee, Aone, ero</p>
            <br/>
            <p><strong>1980's</strong>: Sharp, Delta2, Jonone…</p>
            <br/>
            <br/>
            <p><strong>BLAZE</strong>: pseudonyme, nom de scène de l’artiste.</p>
            <br/>
            <p><strong>BLOCK LETTER</strong>: type de lettrage aux formes carrées ou rectangulaires, généralement réalisé au fat cap ou au rouleau.</p>
            <br/>
            <p><strong>BUBBLE</strong>: lettres aux contours arrondis.</p>
            <br/>
            <p><strong>CAP</strong>: buse ou embout en français. C’est la partie de la bombe par laquelle est projetée la peinture sous la pression du doigt. Les embouts sont interchangeables pour varier l’épaisseur du trait (fat cap, skinny, etc.).</p>
            <br/>
            <p><strong>CREW</strong>: désigne un regroupement d’artistes qui oeuvre ensemble sous un seul et même nom. Les crews participent souvent à des oeuvres collectives ou chacun signe de son blaze. Les artistes peuvent être de différents milieu (dance, graffiti, musique, etc.)</p>
            <br/>
            <p><strong>FAT CAP</strong>: désigne une buse avec un large jet. Cette buse est souvent utilisée pour des travaux rapides, du remplissage de motif et n’est généralement pas gage de précision.</p>
            <br/>
            <p><strong>FLOP</strong>: lettrage arrondi</p>
            <br/>
            <p><strong>GETTING-UP</strong>: action de réaliser des graffiti sur un train</p>
            <br/>
            <p><strong>MONOLAYER</strong>: pochoir réalisé avec une seule matrice mais qui n’exclue pas l’utilisation de plusieurs couleurs.</p>
            <br/>
            <p><strong>OUTLINE</strong>: ce sont les contours d’une lettre, d’une forme. Ce terme est souvent utilisé lorsqu’on parle de lettrage.</p>
            <br/>
            <p><strong>PIECES</strong>: abréviation de masterpiece ou l’exécution d’un lettrage plus compliqué et stylisé avec de multiples couleurs. Cette forme nécessite généralement l’application d ‘un fond.</p>
            <br/>
            <p><strong>POSSE</strong>: voir crew. Terme plus généralement plus utilisé lorsqu’il s’agit de gang, dans un cadre vandal.</p>
            <br/>
            <p><strong>SKINNY</strong>: type de buse permettant la réalisation d’un trait fin et précis.</p>
            <br/>
            <p><strong>TAG</strong>: la forme la plus basique du graffiti moderne, soit la signature du writer/crew avec une simple couleur.</p>
            <br/>
            <p><strong>THROW-UP</strong>: c’est deux ou quatre lettres qui représentent le nom de l’artiste/crew dans le style Bubble avec une couleur marquée pour le outline et une autre pour le remplissage.</p>
            <br/>
            <p><strong>TRASHPAINT</strong>: peinture à la bombe aérosol de mauvaise qualité</p>
            <br/>
            <p><strong>WILD STYLE</strong>: déformation du lettrage ou entrelacs de lettrages stylisés dont la lecture est réservée aux initiés (+voir Bazin)</p>
            <br/>
            <p><strong>WRITER</strong>: artistes qui s’illustrent en peignant leur blaze.</p>
            <br/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoireGlossaire;
